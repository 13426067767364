import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDHXL5Sp7m6ycqGSvfPrJQeeD31V2ajCi4",
    authDomain: "djangotest1-d290a.firebaseapp.com",
    projectId: "djangotest1-d290a",
    storageBucket: "djangotest1-d290a.appspot.com",
    messagingSenderId: "52580555279",
    appId: "1:52580555279:web:5937ff8a4e7a263dc0617a",
    measurementId: "G-ZN10HLKG1D"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

