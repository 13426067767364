import Posts from "./Posts";

const Home = () => {
    return (
        <div>
            <p className="text-2xl text-red-700">
                Home
            </p>
            <Posts />
        </div>
    );
}

export default Home;