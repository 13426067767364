import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { db } from '../firebase';

const ref = collection(db, 'posts');


const Posts = () => {
    const [data, isloading] = useCollectionData(ref);

    if (isloading) {
        return (<h1>Yükleniyor</h1>);
    }

    console.log(data);

    return (
        <div>
            <h1>Posts</h1>
            <div>
                {data.map((post) => (
                    <div id={post.id}>
                        <p>{post.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Posts;